
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiApp } from "@/core/api";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "app-detail",
  components: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const selectData = ref([]);

    const formData = ref({
      id: "",
      status: 10,
      name: "",
      type: "",
      short_key: "",
      sorting: "",
      frontend_base_url: "",
      rest_api_url: "",
      app_logo: "",
      app_sso_url: "",
      rpc_namespace: "",
      overall_notes: "",
    });

    const rules = ref({
      status: [
        {
          required: true,
          message: t("common.status") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      name: [
        {
          required: true,
          message: t("permissions.appName") + t("common.isRequired"),
          trigger: "change",
        },
      ],
      // type: [
      //   {
      //     required: true,
      //     message: "Type is required",
      //     trigger: "change",
      //   },
      // ],
      short_key: [
        {
          required: true,
          message: t("permissions.shortKey") + t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const getDetailData = () => {
      loading.value = true;
      ApiApp.getAppInfo({ id: route.params.id })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            formData.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiApp.updateApp(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
                console.log(data);
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const deleteApp = () => {
      Swal.fire({
        text: t("common.deactivateAppTips"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiApp.deleteApp({ id: route.params.id })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: t("common.appDeactivatedTips"),
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "app" });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    // TODO app type 获取接口
    // const getAppTypeOptions = () => {
    //   ApiBase.getTaggingData({ short_key: "global_app_type" }) // TODO 换接口
    //     .then(({ data }) => {
    //       if (data.code == 0) {
    //         selectData.value = data.data.items;
    //       } else {
    //         showServerErrorMsg(data);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // };

    onMounted(() => {
      setCurrentPageBreadcrumbs(t("permissions.information"), [
        { name: t("permissions.permissions"), path: "" },
        { name: t("permissions.appManagement"), path: "/permissions/app" },
      ]);
      getDetailData();
    });

    return {
      t,
      loading,
      formRef,
      formData,
      rules,
      selectData,
      submit,
      deleteApp,
    };
  },
});
